import * as React from "react"
import Layout from '../components/layout'
import Seo from '../components/seo'
import Button from '@atoms/Button'
import Tail404 from '@images/svg/tail-404.svg'
import HeroTail from '@svg/hero-tail.svg'

const NotFoundPage = () => (
  <div className='flex flex-col justify-between min-h-screen page-404'>
    <Layout headerBg='bg-secondary'>
      <Seo title="Tenko App | 404: Not found" />
      <section className='bg-secondary py-8 md:py-20 relative flex-grow'>
        <div className='max-w-screen-2xl mx-auto px-4 xl:pl-24'>
          <div className='flex flex-wrap items-center -mx-4'>
            <div className='w-full md:w-3/4 px-4'>
              <h1 className='text-white text-3xl mega-title'>400</h1>
              <h3 className='text-3xl text-white'>Oops!</h3>
              <div className='prose text-white'>
                <p>
                  Sorry, we’re not able to find what you were looking for.
                </p>
              </div>
              <div className='mt-8 mb-16'>
                <Button
                  variant='primary'
                  type='internal'
                  isWide
                  url='/'
                  isMobileNarrow
                >
                  Go Back
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Tail404 className='absolute bottom-0 left-0 w-full block pointer-events-none' />
        <HeroTail className='absolute z-10 misc-tail' />
      </section>
    </Layout>
  </div>
)

export default NotFoundPage
