import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const sharedStyle = 'transition-all duration-300 ease-in-out inline-flex items-center justify-center rounded-3xl py-3 px-6 text-sm text-body font-bold focus:outline-none'

const variants = {
  primary: 'bg-primary-80 hover:bg-primary-80 text-text hover:text-white',
  primaryInverted: 'bg-primary-80 hover:bg-primary-80 text-white hover:text-text',
  secondary: 'bg-secondary hover:bg-opacity-90 text-white',
  outline: 'text-white border-2 border-white bg-transparent hover:bg-neutral-100 hover:text-secondary hover:border-neutral-100'
}

const Button = ({ type, variant, url, isWide, isMobileNarrow, disabled, classes, handleClick, children }) => {
  let ButtonComponent = null
  const additionalClasses = classes ? classes : ''
  const isWideButton = isWide ? 'is-wide-button' : ''
  const isMobileButtonNarrow = isMobileNarrow ? 'h-42px md:h-56px' : 'h-56px'
  const disabledClasses = disabled ? 'opacity-60 pointer-events-none' : ''
  const buttonStyles = `${sharedStyle} ${variants[variant]} ${additionalClasses} ${isWideButton} ${isMobileButtonNarrow} ${disabledClasses}`
  switch (type) {
    case 'internal':
      ButtonComponent = () => (
        <Link to={url} className={buttonStyles} disabled={disabled}>
          {children}
        </Link>
      )
      break
    case 'external':
      ButtonComponent = () => (
        <a href={url} target='_blank' rel='noreferrer' className={buttonStyles}>
          {children}
        </a>
      )
      break
    default:
      ButtonComponent = () => (
        <button
          className={buttonStyles}
          onClick={handleClick}
          disabled={disabled}
        >
          {children}
        </button>
      )
      break
  }

  return (
    <ButtonComponent />
  )
}

Button.propTypes = {
  type: PropTypes.string,
  url: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

Button.defaultProps = {
  type: ``,
  variant: ``,
  url: ``,
}

export default Button
